import { Container, GlobalStyles, Stack, Typography } from "@mui/material";
import { gettext } from "@services/i18n";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import {
  submitWaitlistForm,
  waitlistFormSchema,
  WaitListFormValues,
} from "../../../services/waitlist";
import { isAxiosError } from "axios";
import { ReserveNowOrSuccessContent } from "./ReserveNowOrSuccessContent";
import { ResponsiveDrawer } from "@components/common/modal/ResponsiveDrawer";
import { Grid } from "../../../../components/common/layout/Grid";
import { TextFieldWithController } from "@components/common/input/TextField";
import { useAppSnackbar } from "@services/snackbar";
import { logger } from "@services/logger";
import LoadingButton from "@mui/lab/LoadingButton";
import { appContext } from "@services/appContext";
import CkCapturedKnowledgeLogo from "../../../../../images/CKCapturedKnowledge.svg?react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export function WaitlistScreen() {
  const [isInviteFormOpen, setIsInviteFormOpen] = useState(false);

  const { enqueueSnackbar } = useAppSnackbar();

  const { control, handleSubmit, setError } = useForm({
    resolver: yupResolver(waitlistFormSchema),
  });

  const submitInviteFormMutation = useMutation({
    mutationFn: (inviteFormValues: WaitListFormValues) => {
      return submitWaitlistForm(inviteFormValues);
    },
    onError: (error) => {
      if (isAxiosError(error) && error.response.data.email) {
        setError("email", {
          type: "validate",
          message: error.response.data.email[0],
        });
      } else {
        enqueueSnackbar(
          gettext(
            `Sorry something went wrong adding you to the waitlist. Contact us at ${appContext.supportEmail} to get on the waitlist`,
          ),
          { variant: "error", autoHideDuration: 10000 },
        );
        logger.error(
          "An unexpected error occurred while submitting to the waitlist",
          { error },
        );
      }
    },
  });

  const theme = useTheme();
  const isMediumAndDownBreakpoint = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Container sx={{ height: "100vh" }}>
      <ResponsiveDrawer
        open={isInviteFormOpen && !submitInviteFormMutation.isSuccess}
        onClose={() => setIsInviteFormOpen(false)}
        title={gettext("Reserve your spot")}
      >
        <Grid p={2}>
          <form
            onSubmit={handleSubmit((formValues) =>
              submitInviteFormMutation.mutate(formValues),
            )}
          >
            <Stack spacing={2}>
              <TextFieldWithController
                label={gettext("Email")}
                control={control}
                name="email"
              />
              <TextFieldWithController
                label={gettext("First Name")}
                control={control}
                name="firstName"
              />
              <TextFieldWithController
                label={gettext("Last Name")}
                control={control}
                name="lastName"
              />
              <TextFieldWithController
                label={gettext("Company Name")}
                control={control}
                name="companyName"
              />
              <TextFieldWithController
                label={gettext("Ticketing System")}
                control={control}
                name="ticketingSystem"
                placeholder="Domizil+"
              />
              <LoadingButton
                color="primary"
                type="submit"
                variant="contained"
                loading={submitInviteFormMutation.isPending}
              >
                {gettext("Submit")}
              </LoadingButton>
            </Stack>
          </form>
        </Grid>
      </ResponsiveDrawer>
      <GlobalStyles styles={{ body: { backgroundColor: "#fff" } }} />
      <Grid
        container
        maxWidth="xl"
        size={{ xs: 12 }}
        textAlign={"center"}
        justifyContent="center"
        alignContent="center"
        sx={{ height: "100%" }}
        rowGap={3}
        spacing={2}
      >
        <Grid
          size={{ xs: 12 }}
          justifyContent="center"
          alignContent="center"
          mb={isMediumAndDownBreakpoint ? 1 : 0}
        >
          <CkCapturedKnowledgeLogo
            width={isMediumAndDownBreakpoint ? "100%" : 800}
          />
          <Typography variant="subtitle1">
            {gettext("An AI assistant for Property Managers")}
          </Typography>
        </Grid>
        <Grid size={{ xs: 9 }}>
          <ReserveNowOrSuccessContent
            isSuccess={submitInviteFormMutation.isSuccess}
            onReserveNowClick={() => setIsInviteFormOpen(true)}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
